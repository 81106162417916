import React, { Fragment, useEffect, useRef, useState } from "react";
import '../styles/commentMenu.scss';

const CommentMenu = ({ isOwnComment, display, setEditDisplay, setError, submitEdits,
  commentId, setIsDeleted, setDisplay, }) => {
  const componentRef = useRef(null);

  const deleteComment = async () => {
    // clear error message
    setError('');

    try {
      // make delete request to server
      const response = await fetch(`${process.env.HOST}/comment/${commentId}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      if (!response.ok) {
        const errorObj = await response.json();
        throw new Error(errorObj.errorMessage);
      };
      setIsDeleted(true);
    }
    catch (err) {
      console.log(err);
      if (err.message === 'Error: Invalid credentials') setError(err.message);
      else setError('Failed to delete comment');
    }
  }
  const showEditDiv = () => {
    // hide menu
    setDisplay('none');

    // hide the current comment and display text area
    setEditDisplay('block');
  }
  const reportComment = async () => {
    try {
      const response = await fetch(`${process.env.HOST}/comment/report/${commentId}`, {
        method: 'PATCH'
      })
      if (!response.ok) throw new Error();
      console.log({response})
      setError('Comment reported successfully');
      setTimeout(() => setIsDeleted(true), 2000);
    } catch (err) {
      console.log(err);
      setError('Failed to report comment');
    }
  }

  // hide menu on click outside menu window
  useEffect(() => {
    document.body.addEventListener('click', (e) => {
      // check if component has been removed from DOM
      if (!componentRef.current) return;

      // check if click event not triggered on menu or corresponding icon and remove from DOM
      if (!componentRef.current.contains(e.target) && !componentRef.current.parentElement.querySelector('.commentMenuIcon').contains(e.target)) {
        componentRef.current.style.display = 'none';
      }
    })
  }, [])


  return (
    <ul
      className="commentMenu"
      style={{ display }}
      ref={componentRef}>
      {
        isOwnComment
          ?
          <Fragment>
            <li onClick={showEditDiv}>Edit Comment</li>
            <li onClick={deleteComment}>Delete Comment</li>
          </Fragment>
          :
          <li onClick={reportComment}>Report Comment</li>
      }
    </ul>
  );
};

export default CommentMenu;