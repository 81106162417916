import React from "react";
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppState } from "../App.jsx";
import '../styles/auth.scss';
import { XLoginButton } from "react-social-login-buttons";
import { zoomies } from 'ldrs';
zoomies.register('load-bar');


const Login = () => {
  const context = useContext(AppState);
  const { setUsername, username } = context;
  const [newUsername, setNewUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [passwordDisplay, setPasswordDisplay] = useState('password');
  const [isLoading, setIsLoading] = useState(false);

  // check if user is logged in. navigate to profile page
  useEffect(() => {
    if (username) navigate('/profile');
  }, [username]);

  const navigate = useNavigate();

  const submitForm = async (e) => {
    e.preventDefault(); // prevent form submission
    setError(''); // clear previous error message

    const userInfo = { username: newUsername, password };

    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.HOST}/auth/login`, {
        method: 'POST',
        body: JSON.stringify(userInfo),
        headers: {
          'Content-Type': 'application/JSON'
        },
        credentials: 'include'
      })
      setIsLoading(false);
      if (!response.ok) throw new Error();
      setUsername(newUsername);
      navigate(`/profile`);

    }
    catch (err) {
      setError('Login Failed. Please try again');
      console.log('Error in login' + err);
    }

    return;
  }

  return (
    <div id="auth">
      <form onSubmit={submitForm}>
      <div id="contentPolicyLink">
        <a
          href="/contentPolicy"
        >
          Content Policy
        </a>
      </div>
        <h1>Login</h1>
        <div>
          <label htmlFor="username">Username: </label>
          <input
            type="text"
            value={newUsername}
            onChange={(e) => setNewUsername(e.target.value)}
            id="username"
          ></input>
        </div>
        <div>
          <label htmlFor="password">Password: </label>
          <input
            type={passwordDisplay}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            id="password"
          ></input>
        </div>
        <div>
          <input type="checkbox" onClick={() => setPasswordDisplay(passwordDisplay === "password" ? "text" : "password")}></input>
          <label >Show password</label>
        </div>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {isLoading && <load-bar color="red"></load-bar>}
        <div id="submitButtonDiv">
          <button type="submit" className='redButton'>Log In </button>
        </div>
        <h3>-----or-----</h3>
        <a
          href={`${process.env.HOST}/auth/twitter/initiate/0`}
          target="_blank"
          rel="noreferrer noopener"
        >
          <XLoginButton />
        </a>
        <button className="supportButton redButton"
          type="button"
          onClick={() => navigate('/supportMe')}
        >Support the creator!</button>
      </form>
    </div>
  )
}

export default Login;