import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { zoomies } from 'ldrs';
zoomies.register('load-bar');
import { AppState } from "../App.jsx";
import CommentSortDropdown from "./CommentSortDropdown.jsx";
import '../styles/commentSection.scss';
import Comment from "./Comment.jsx";


const CommentSection = () => {
  const [newComment, setNewComment] = useState('');
  const [sortMethod, setSortMethod] = useState('Newest');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [allCommentsLoaded, setAllCommentsLoaded] = useState(false);
  const [commentBox, setCommentBox] = useState([]);

  const context = useContext(AppState);
  const { episodeCode, postToTwitter, videoData, username } = context;

  // functions invoked by an event listener only have access to initial state, 
  // so we'll need a reference to current state later when we invoke postComment from an event listener
  const commentRef = useRef(newComment);
  const codeRef = useRef(episodeCode);


  const fetchComments = async (sortMethod = sortMethod, lastCommentId) => {
    setIsLoading(true);
    setAllCommentsLoaded(false);
    if (!lastCommentId) lastCommentId = '';
    // make a get request for the episode comments

    // if episodecode undefined, get value from url
    const splitUrl = window.location.href.split('/');
    const backupCode = splitUrl[4];
    try {
      const response = await fetch(`${process.env.HOST}/comment/get/${episodeCode || backupCode}/${sortMethod}/${lastCommentId}`, {
        credentials: 'include'
      });
      if (!response.ok) throw new Error('response not ok');
      const comments = await response.json();
      if (comments.length < 10) setAllCommentsLoaded(true);
      comments.forEach(comment => comment.time = formatDate(comment.time));
      setIsLoading(false);
      setCommentBox(previous => [...previous, ...comments]);
      return true;
    } catch (err) {
      setIsLoading(false);
      setError('Failed to load comment section');
      console.log('failed fetching comments', err);
      return false;
    }
  }
  const formatDate = (iso8601) => { // converts the ISO8601 timestamp a more human readable format, converts timezone from UTC
    const arrayDate = new Date(iso8601).toString().split(' ').slice(1, 5);

    // convert 24 hour time to AM/PM
    let time = arrayDate[3]
    let hour = Number(time.slice(0, 2));
    const amPm = hour >= 12 ? 'PM' : 'AM';
    if (hour > 12) hour -= 12;
    arrayDate[3] = hour + time.slice(2, 5) + ' ' + amPm;

    return arrayDate.join(' ');
  }

  const getCommentTimestamp = (e) => {
    e.preventDefault();

    // BEFORE entering the context of the event listener, update the state references
    commentRef.current = newComment;
    codeRef.current = episodeCode;

    // once response recieved from getCommentTimestamp, invoke postComment
    window.addEventListener('message', (e) => {
      const message = e.data;
      if (typeof message === 'string' && message.split('/')[0] === 'timestamp') {
        try {
          const timestamp = message.split('/')[1];
          postComment(timestamp);
        } catch (err) {
          postComment(null);
          console.log('error getting timestamp' + err);
        }
      }
    }, { once: true });

    // send message to event listener on netflix (created in getTimeInjectedScript) that sends timestamp back to the iframe
    window.parent.postMessage('getCurrentTime', '*');
  }

  const loadMoreComments = async () => {
    const lastCommentId = commentBox[commentBox.length - 1] ? commentBox[commentBox.length - 1].comment_id : '';
    setIsLoading(true);
    fetchComments(sortMethod, lastCommentId);
  }
  const postComment = async (timestamp) => {
    //comment cannot be empty
    if (commentRef.current.trim().length < 1) {
      setError('Comment cannot be empty');
      return;
    }

    // clear previous errors on submit
    setError('');

    const commentData = { body: commentRef.current, timestamp, postToTwitter, videoData };

    try {
      const response = await fetch(`${process.env.HOST}/comment/create/${codeRef.current}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/JSON',
        },
        body: JSON.stringify(commentData),
        credentials: 'include'
      })
      if (!response.ok) throw new Error();
      const { comment_id, time } = await response.json();
      console.log({time})
      setCommentBox((initial) => [{ ...commentData, user: username, comment_id, likeCount: 0, time: formatDate(time), isOwnComment: true }, ...initial]);
      document.getElementById('commentContainer').scrollTop = 0;
      setNewComment('');

    } catch (err) {
      console.log('failed to post comment' + err);
      setError('ERROR: Failed to post comment');
    }

  }
  const reloadComments = async (newSortMethod) => {
    setError('');
    if (fetchComments(newSortMethod)) {
      setCommentBox([]);
      return true;
    }
    return false;
  };


  // load comments on initial page load, get comments
  useEffect(() => {
    fetchComments(sortMethod);
  }, []);


  return (
    <div id="commentSection">
      <div id="commentContainer">
        {commentBox.map((comment, index) => {
          return (
          <
            Comment
            key={comment.comment_id} 
            id={comment.comment_id}
            body={comment.body}
            user={comment.user}
            timestamp={comment.timestamp}
            isOwnComment={comment.isOwnComment}
            time={comment.time}
            initialLikes={comment.likeCount}
            userLikedComment={comment.userLikedComment}
            isGray={index % 2}
          />)
        })}
        {
          isLoading
            ? <load-bar color="red"></load-bar>
            : (!allCommentsLoaded && <button className='redButton' id="loadMore" onClick={loadMoreComments}>Load more comments</button>)
        }
      </div>
      <div id="postComment">
        <CommentSortDropdown setIsLoading={setIsLoading} setSortMethod={setSortMethod} sortMethod={sortMethod} reloadComments={reloadComments}></CommentSortDropdown>
        <form>
          <textarea
            rows={4}
            placeholder={'Post a comment'}
            value={newComment}
            onChange={(e) => { setNewComment(e.target.value); setError('') }}
            onKeyDown={(e) => { if (e.key === 'Enter') getCommentTimestamp(e) }}
            maxLength="500"
          ></textarea>
        </form>
      </div>
      <div>{<p className="error">{error}</p>}</div>
    </div>
  )
}

export default CommentSection;