import React from "react";

const ContentPolicy = () => {
  return (
    <div style={{padding: '10px'}}>
    <section style={{display: 'flex', justifyContent: 'center'}}>
      <h3 style={{margin: '0px'}}>Content Policy</h3>
    </section>
    <section>
      <h4>Please abide by all rules while commenting and report any rule-breaking comments</h4>
    </section>
    <ol style={{paddingLeft: '15px'}}>
      <li>NO SPOILERS! Please don't make comments about future episodes or later timestamps</li>
      <li>Keep it legal, and avoid posting illegal content or soliciting or facilitating illegal or prohibited transactions.</li>
      <li>No discrimination, stereotyping, or hate speech, including but not limited to discrimination by race, sex, gender identity, sexual orientation, religion, and disability.</li>
      <li>No inciting violence.</li>
      <li>No harassing or making fun of other users. Keep any discourse on topic. Making fun of characters in the show is allowed within reason, as long as it doesn't break any other rules.</li>
      <li>Posting any sexual comments or linking to anything with nudity or pornographic content is not allowed.</li>
    </ol>
    </div>
  )
}

export default ContentPolicy;