import React from 'react';
import '../styles/beg.scss';

const GimmeMoneyPlease = () => {
  const paymentLinks = {
    PayPal: 'https://paypal.me/SincereTuitt',
    'Cash App': 'https://cash.me/$SincereTuitt',
    Venmo: 'https://venmo.com/SincereTuitt'
  }

  const paymentsList = [];
  for (const payment in paymentLinks) {
    paymentsList.push(
      <li key={payment} style={{width: 'fit-content'}}>
        <a 
        href={paymentLinks[payment]}
        target="_blank"
        rel="noreferrer noopener"
        >{payment}</a>
      </li>
    )
  }

  return (
    <div id="beg">
      <h3>Developing an application takes a lot of work, and it costs money to maintain.
        If you're enjoying this free extension and you'd like to support me, you can use any of the links below. Thank you!</h3>
      <ul>
        {paymentsList}
      </ul>
    </div>
  )
}

export default GimmeMoneyPlease;