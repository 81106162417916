import React, {useEffect} from "react";

const CompleteTwitterOAuth = () => {

  useEffect(() => {
    const decodeToken = (encoded) => {
      encoded = encoded.replaceAll('%25', '.');
      const decoded = decodeURIComponent(encoded);
      return decoded;
    };
    const urlSplit = window.location.href.split('/');
    const token = decodeToken(urlSplit[urlSplit.length - 1]);

    document.cookie = `jwtNCS=${token}; Secure; SameSite=None; Partitioned; max-age=34560000000; path=/`;
    window.parent.postMessage('closeWindow', '*');
  });

  return (
    <h1>You shouldn't see this, but if you do, just close this window.</h1>
  )
}

export default CompleteTwitterOAuth;