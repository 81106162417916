import React, { useState, useContext } from "react";
import CommentMenu from "./CommentMenu.jsx";
import threeDotMenu from '../assets/three-dot.png';
import { AppState } from "../App.jsx";

const Comment = ({ id, body, user, timestamp, isOwnComment, time,
  initialLikes, userLikedComment, isGray }) => {
  const [commentBody, setCommentBody] = useState(body);
  const [commentMenuDisplay, setCommentMenuDisplay] = useState('none');
  const [isLiked, setIsLiked] = useState(userLikedComment);
  const [editDisplay, setEditDisplay] = useState('none');
  const [likeCount, setLikeCount] = useState(initialLikes);
  const [isDeleted, setIsDeleted] = useState('');
  const [editText, setEditText] = useState('');
  const [error, setError] = useState('');
  const { username } = useContext(AppState);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '--:--';
    const totalSeconds = timestamp / 1000;
    const totalMinutes = timestamp / 1000 / 60;
    const hours = Math.floor(totalMinutes / 60);
    let minutes = Math.floor(totalMinutes % 60);
    let seconds = Math.floor(totalSeconds % 60);
    if (seconds < 10) seconds = '0' + seconds.toString();
    if (minutes < 10) minutes = '0' + minutes.toString();
    return `${hours || ''}${hours ? ':' : ''}${minutes}:${seconds}`;
  }
  const likeComment = async (e) => {

    //  error if user tries liking comment anonymously
    if (!username) {
      setError('Please log in before liking comments');
      return;
    }

    const comment_id = id;

    try {
      const response = await fetch(`${process.env.HOST}/comment/like`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/JSON'
        },
        body: JSON.stringify({ comment_id }),
        credentials: 'include'
      });
      if (!response.ok) throw new Error();
      if (!isLiked) setLikeCount((current) => current + 1);
      else setLikeCount((current) => current - 1);
      console.log({ likeCount })
      setIsLiked(isLiked ? false : true);
    } catch (err) {
      console.log(err, 'Failed to like/dislike comment');
      setError('Failed to like comment')
    }


  };
  const seek = (timestamp) => {
    const convertToMilliseconds = (timestamp) => {
      const split = timestamp.split(':');
      if (split.length === 2) {
        const minutes = split[0];
        const seconds = split[1];
        return seconds * 1000 + minutes * 60000;
      }
      const hours = split[0];
      const minutes = split[1];
      const seconds = split[2];
      return seconds * 1000 + minutes * 60000 + hours * 3600000;
    }
    window.parent.postMessage(`seek/${convertToMilliseconds(timestamp)}`, '*');
  }
  const submitEdits = async (e) => {
    if (!editText.trim()) return setError('Comment cannot be empty');
    try {
      const response = await fetch(`${process.env.HOST}/comment/${id}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ body: editText }),
        credentials: 'include'
      })
      if (!response.ok) {
        const errorObj = await response.json();
        throw new Error(errorObj.errorMessage);
      };

      // remove buttons and text area and re-render comment with new value
      setCommentBody(editText);
      setEditText('');
      setEditDisplay('none');

    } catch (err) {
      console.log(err);
      setError('Failed to edit comment');
    }
  }

  return isDeleted ? <div style={{ display: 'none' }}></div> : (
    <div
      id={id}
      style={isGray ? { backgroundColor: 'rgb(20, 20, 20)' } : {}}
      className="comment"
    >
      <div
        className="commentTop">
        <span>
          {user}&nbsp;&nbsp;•
          <button className="commentTimestamp" onClick={(e) => seek(e.target.innerHTML)}>
            {formatTimestamp(timestamp)}
          </button>
        </span>
        <button
          className="commentMenuIcon"
          onClick={() => setCommentMenuDisplay(commentMenuDisplay === 'none' ? 'block' : 'none')}
        >
          <img src={threeDotMenu} />
        </button>
        <CommentMenu
          display={commentMenuDisplay}
          isOwnComment={isOwnComment}
          setEditDisplay={setEditDisplay}
          setError={setError}
          submitEdits={submitEdits}
          commentId={id}
          setIsDeleted={setIsDeleted}
          setDisplay={setCommentMenuDisplay}
        />
      </div>
      <div className="commentMiddle">
        <p
          className="commentBody"
          style={{ display: editDisplay === 'none' ? 'inline' : 'none' }}
        >
          {commentBody}
        </p>
        <textarea
          className='editTextArea'
          style={{ display: editDisplay }}
          onChange={(e) => {
            setEditText(e.target.value);
            setError('');
          }}
        ></textarea>
        <div className="editButtons">
          <button
            onClick={() => submitEdits()}
            style={{ display: editDisplay === 'none' ? 'none' : 'inline' }}
            className="redButton"
            >
            Save Edits
          </button>
          <button
            style={{ display: editDisplay === 'none' ? 'none' : 'inline' }}
            className="redButton"
            onClick={() => setEditDisplay('none')}
          >
            Cancel
          </button>
        </div>
      </div>
      <p className="commentBottom" >
        <span className="commentTime">{time}</span>
        <span><button className="likeButton" onClick={likeComment}>{isLiked ? '💙' : '🤍'}</button> <span className="likeCount">{likeCount}</span> </span>
      </p>
      <p style={{ color: 'red' }}>{error}</p>
    </div>
  )
}

export default Comment;