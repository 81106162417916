import React from "react";
import { createRoot } from 'react-dom/client';
import App from './App.jsx';

const domNode = document.getElementById('root');
const root = createRoot(domNode);
import './styles/main.scss'

root.render(
  // process.env.NODE_ENV === 'development'
  // ? 
  // (<React.StrictMode>
  //   <App />
  // </React.StrictMode>)
  // : 
  <App />
);