import React from "react";
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppState } from "../App.jsx";
import '../styles/auth.scss';
import { XLoginButton } from "react-social-login-buttons";
import { zoomies } from 'ldrs';
zoomies.register('load-bar');

const Signup = () => {
  const context = useContext(AppState);
  const { episodeCode, username, setUsername } = context;

  const [newUsername, setNewUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [passwordDisplay, setPasswordDisplay] = useState('password');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  // check if user is logged in. navigate to profile page
  useEffect(() => {
    if (username) navigate('/profile')
  }, [username]);

  // a function to validate that fields do not contain illegal inputs. Sets error message if fails
  const validateForm = () => {

    // username must pass regex validation
    const userRegex = /^[a-zA-Z0-9_]+$/;

    if (newUsername.length > 20 || newUsername.length < 3 || !userRegex.test(newUsername)) {
      setError('Username must be between 3 and 20 characters and contain only letters, numbers, and underscores');
      return false;
    }
    if (password.length > 40 || password.length < 5) {
      setError('Password must be at least 5 characters, but longer passwords are more secure!');
      return false;
    }
    if (confirmPassword !== password) {
      setError('Passwords must match');
      return false;
    }

    return true;
  }

  const submitForm = async (e) => {
    e.preventDefault(); // prevent form submission
    setError(''); // clear previous error message

    if (!validateForm()) return; // do not proceed if validation fails

    const userInfo = { username: newUsername, password };

    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.HOST}/auth/signup`, {
        method: 'POST',
        body: JSON.stringify(userInfo),
        headers: {
          'Content-Type': 'application/JSON'
        },
        credentials: 'include'
      });
      setIsLoading(false);
      if (!response.ok) {
        const errorObj = await response.json()
        throw new Error(errorObj.errorMessage);
      }
      setUsername(newUsername);
      navigate(`/comments/${episodeCode}`);
    }
    catch (err) {
      if (err.message === 'Error: Username taken') setError(err.message);
      else setError('Server error. Close the comment section and try again');
    }
  }



  return (
    <div id="auth" >
      <form onSubmit={submitForm}>
      <div id="contentPolicyLink">
        <a
          href="/contentPolicy"
        >
          Content Policy
        </a>
      </div>
        <h1>Sign Up</h1>
        <div>
          <label htmlFor="username">Username: </label>
          <input
            type="text"
            value={newUsername}
            onChange={(e) => setNewUsername(e.target.value)}
            id="username"
          ></input>
        </div>
        <div>
          <label htmlFor="password">Password: </label>
          <input
            type={passwordDisplay}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            id="password"
          ></input>
        </div>
        <div>
          <label htmlFor="confirmPassword">Confirm Password: </label>
          <input
            type={passwordDisplay}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            id="confirmPassword"
          ></input>
        </div>
        <div>
          <input type="checkbox" onClick={() => setPasswordDisplay(passwordDisplay === "password" ? "text" : "password")}></input>
          <label>Show password</label>
        </div>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {isLoading && <load-bar color="red"></load-bar>}
        <div id="submitButtonDiv">
          <button type="submit" className='redButton'>Sign Up </button>
        </div>
        <h3>-----or-----</h3>
        <a
          href={`${process.env.HOST}/auth/twitter/initiate/0`}
          target="_blank"
          rel="noreferrer noopener"
        >
          <XLoginButton className="xLoginButton"/>
        </a>
        <br />
        <button className="supportButton redButton"
          type="button"
          onClick={() => navigate('/supportMe')}
        >Support the creator!</button>
      </form>
    </div>
  )
}

export default Signup;