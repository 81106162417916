import React, { createContext, useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CommentSection from "./components/CommentSection.jsx";
import NavBar from "./components/NavBar.jsx";
import Signup from './components/Signup.jsx';
import Login from './components/Login.jsx';
import GimmeMoneyPlease from "./components/GimmeMoneyPlease.jsx";
import CloseWindow from './components/CloseWindow.jsx';
import Profile from "./components/Profile.jsx";
import CompleteTwitterOAuth from "./components/CompleteTwitterOAuth.jsx";
import ContentPolicy from "./components/ContentPolicy.jsx";

// createContext for small-scale state management, just to tell other components when to refresh
export const AppState = createContext();

const App = () => {
  const [episodeCode, setEpisodeCode] = useState('');
  const [username, setUsername] = useState('');
  const [twitterScreenName, setTwitterScreenName] = useState('');
  const [postToTwitter, setPostToTwitter] = useState(false);
  const [videoData, setVideoData] = useState(null);

  // gather state data on initial app render
  useEffect(() => {
    // get episode code (videoCode)
    window.parent.postMessage('getVideoCode', '*');

    // get video data (title, episode, season)
    window.parent.postMessage('getVideoData', '*');

    window.addEventListener('message', (e) => {
      const message = e.data;
      if (typeof message === 'object' && message.messageContent === 'videoData') {
        setVideoData(message);
      }
      if (typeof message === 'object' && message.messageContent === 'videoCode') {
        setEpisodeCode(message.videoCode);
      }
    });

    // get current value of postToTwitter from local storage
    const storedPostToTwitter = localStorage.getItem('postToTwitter');
    if (storedPostToTwitter) setPostToTwitter(storedPostToTwitter === 'true' ? true : false);

    // create an event listener that will reload the page on if on /profile, /login, or /signup
    document.addEventListener('visibilitychange', () => {
      const urlSplit = window.location.href.split('/');
      const page = urlSplit[3];
      const refreshPages = ['profile', 'login', 'signup'];
      if (!document.hidden && refreshPages.includes(page)) location.reload();
    })

  }, []);


  return (
    <BrowserRouter>
      <AppState.Provider value={{ episodeCode, setEpisodeCode, username, setUsername, twitterScreenName, setTwitterScreenName, postToTwitter, setPostToTwitter, videoData }}>
        <NavBar />
        <CloseWindow />
        <Routes>
          <Route path="/signup" element={<Signup />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="/comments/*" element={<CommentSection />}></Route>
          <Route path="/twitterOAuth/:jwt" element={<CompleteTwitterOAuth />}></Route>
          <Route path="/supportMe" element={<GimmeMoneyPlease />}></Route>
          <Route path="/contentPolicy" element={<ContentPolicy />}></Route>
        </Routes>
      </AppState.Provider>
    </BrowserRouter>
  )
}

export default App;