import React from "react";
import '../styles/closeWindow.scss'

const CloseWindow = () => {

  const onClick = async () => {
    window.parent.postMessage('close iframe', '*');
  }

  return (
    <button id="closeButton" onClick={onClick}>x</button>
  )
}

export default CloseWindow;