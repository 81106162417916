import React, { useContext, useEffect, useState } from "react";
import { AppState } from "../App.jsx";
import { useNavigate } from "react-router-dom";
import '../styles/profile.scss';
import xIcon from '../assets/x-icon.png';
import { zoomies } from 'ldrs';
zoomies.register('load-bar');

const Profile = () => {
  const [newUsername, setNewUsername] = useState('');
  const [credentialsError, setCredentialsError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const context = useContext(AppState);
  const { username, setUsername, twitterScreenName, setTwitterScreenName, postToTwitter, setPostToTwitter } = context;

  const navigate = useNavigate();

  const authenticateTwitter = async () => {
    try {
      // get the jwt value from the server side
      const getCookieData = await fetch(`${process.env.HOST}/auth/cookieValues`, {
        credentials: 'include'
      });
      const cookieData = await getCookieData.json();
      const jwt = cookieData.jwtNCS;
      const encodedToken = encodeURIComponent(jwt);

      window.open(`${process.env.HOST}/auth/twitter/initiate/${encodedToken}`);
    } catch (err) {
      console.log(err)
    }

  };

  const signOut = async () => {
    try {
      const signoutSuccesful = await fetch(`${process.env.HOST}/auth/signout`, {
        method: 'POST',
        credentials: 'include'
      });
      if (!signoutSuccesful.ok) throw new Error();
      setUsername('');
      localStorage.removeItem('postToTwitter');
      sessionStorage.removeItem('username');
      setPostToTwitter(false);
      navigate('/signup');
    } catch (err) {
      console.log('Failed to sign out');
    }
  }

  const togglePostToTwitter = (e) => {
    setPostToTwitter(e.target.checked ? true : false);
    localStorage.setItem('postToTwitter', e.target.checked ? 'true' : 'false');
  }

  const changeUsername = async (e) => {
    e.preventDefault();

    // clear previous error message
    setCredentialsError('');

    // validate input
    const validateUsername = () => {
      // username must pass regex validation
      const userRegex = /^[a-zA-Z0-9_]+$/;

      if (newUsername.length > 20 || newUsername.length < 3 || !userRegex.test(newUsername)) {
        setCredentialsError('Username must be between 3 and 20 characters and contain only letters, numbers, and underscores');
        return false;
      }
      return true;
    }
    if (!validateUsername()) return;

    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.HOST}/auth/new/username`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ newUsername }),
        credentials: 'include'
      });
      setIsLoading(false);
      if (!response.ok) {
        const errorObj = await response.json()
        throw new Error(errorObj.errorMessage);
      }
      sessionStorage.setItem('username', newUsername);
      setUsername(newUsername);
      setNewUsername('');
      setSuccessMessage('Username change successful');
    } catch (err) {
      console.log(err);
      if (err.message === 'Error: Username taken') setCredentialsError(err.message);
      else setCredentialsError('Server error. Close the comment section and try again');
    }
  }

  // get socials data on initial render
  useEffect(() => {
    const getSocialsData = async () => {
      try {
        const response = await fetch(`${process.env.HOST}/auth/socialsData`, {
          credentials: 'include'
        });
        if (!response.ok) throw new Error('response not ok');
        const { twitterHandle } = await response.json();
        setTwitterScreenName(twitterHandle);
      } catch (err) {
        console.log('error getting socials data', err);
      }
    };
    getSocialsData();
  });


  return (
    <div id="profile">
      <div id="contentPolicyLink">
        <a
          href="/contentPolicy"
        >
          Content Policy
        </a>
      </div>
      <h3>Change user credentials:</h3>
      <form onSubmit={changeUsername}>
        <label>Username</label>
        <input
          type="text"
          placeholder={username}
          onChange={(e) => {
            setNewUsername(e.target.value)
            setSuccessMessage('');
          }} />
        <button className='redButton'>Change username</button>
      </form>
      {isLoading && <load-bar color="red"></load-bar>}
      {credentialsError && <p style={{ color: 'red' }}>{credentialsError}</p>}
      <p style={{ color: 'green' }}>{successMessage}</p>
      <br />
      <h3>Manage Socials:</h3>
      {
        twitterScreenName
          ? <React.Fragment>
            <div className="twitterScreenName">
              <img src={xIcon} className="socialsIcon" />
              <span>
                @{twitterScreenName}
              </span>
            </div>
            <input type="checkbox" checked={postToTwitter} onClick={togglePostToTwitter}></input>
            <label style={{ margin: '5px', display: 'inline-block' }}>Post comments to Twitter</label>
            <br />
          </React.Fragment>
          : <React.Fragment>
            <div className="twitterScreenName"
              onClick={authenticateTwitter}
              style={{ 'cursor': 'pointer' }}
            >
              <img src={xIcon} className="socialsIcon" />
              <span>
                Connect to Twitter account
              </span>
            </div>
            <p>Connecting to  Twitter gives you the option to have comments that you write here get
              posted to your Twitter account. It also allows you to log in with you Twitter account</p>
          </React.Fragment>
      }
      <br />
      <br />
      <br />
      <br />
      <button className='redButton' onClick={signOut}>Sign out</button>
      <br />
      <br />
      <button className="redButton" id="supportButton" onClick={() => navigate('/supportMe')}>Help support this application!</button>
      <p>Coming soon: Add email address, change password with email authentication, connect to Facebook account, view comment history, reply to comments, spoilers tags</p>
    </div>
  )
};

export default Profile;