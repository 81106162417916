import React, { useEffect, useState } from "react";
import '../styles/dropdown.scss'

const CommentSortDropdown = ({ sortMethod, setSortMethod, reloadComments }) => {
  const [dropdownVisibility, setDropdownVisibility] = useState('none');
  const sortOptions = ['Newest', 'Oldest', 'Most Liked', 'Earliest Timestamp', 'Latest Timestamp']

  const showHideDropdown = () => {
    const newDisplayValue = dropdownVisibility === 'none' ? 'block' : 'none';
    setDropdownVisibility(newDisplayValue);
  };
  const onClickSortButton = () => {
    showHideDropdown();
  };
  const clickDropdownItem = async (e) => {
    const newSortMethod = e.target.innerHTML;
    if (await reloadComments(newSortMethod)) {
      setSortMethod(newSortMethod);
      showHideDropdown();
    };
  };

  // change dropdown item color if it matches current sort method
  useEffect(() => {
    const listItems = document.querySelectorAll('#sortDropdown li');
    listItems.forEach((li) => {
      const itemName = li.innerHTML;
      if (itemName === sortMethod) li.style.backgroundColor = 'rgb(30, 30, 30)';
      else li.style.backgroundColor = 'black';
    })
  }, [sortMethod])

  // hide dropdown when clicking outside menu
  useEffect(() => {
    document.body.addEventListener('click', (e) => {
      if (
        document.getElementById('sortDropdown')
        && e.target.id !== 'sortButton'
        && !document.getElementById('sortDropdown').contains(e.target)) {
        setDropdownVisibility('none');
      }
    })
  }, [])



  return (
    <div id="sortDiv">
      <ul id="sortDropdown" style={{ display: dropdownVisibility }}>
        <h4>Sort by</h4>
        {sortOptions.map((option, index) => {
          return <li onClick={(e) => clickDropdownItem(e)} key={index}>{option}</li>
        })}
      </ul>
      <span>Sort by: </span>
      <button type="button" id="sortButton" onClick={onClickSortButton} >{sortMethod} ↑</button>
    </div>
  )
};

export default CommentSortDropdown;