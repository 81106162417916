import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppState } from "../App.jsx";
import '../styles/navBar.scss'

const NavBar = () => {
  const context = useContext(AppState);
  const { episodeCode, username, setUsername } = context;

  const navigate = useNavigate();

  // fetch username from back end and navigate to comment section on login/logout
  useEffect(() => {
    const getUsername = async () => {
      // check for username in session storage
      const username = sessionStorage.getItem('username');
      if (username) {
        setUsername(username);
        return;
      }

      // get username from server
      try {
        const response = await fetch(`${process.env.HOST}/auth/username`, {
          credentials: 'include'
        });
        if (!response.ok) throw new Error();
        const username = await response.text();
        if (username === 'anonymous'){
          setUsername('');
        }
        else {
          sessionStorage.setItem('username', username);
          setUsername(username);
        }
      } catch (err) {
        setUsername('');
      }
    };
    getUsername();

  }, [username])


  return (
    <div id="navbar">
      <p>{username ? `Logged in as ${username}` : 'Not logged in. Posting anonymously'}</p>
      <div id="buttondiv">
        {
          username ?
            <React.Fragment>
              <button className='redButton' onClick={() => navigate('/profile')}>Profile</button>
            </React.Fragment> :
            <React.Fragment>
              <button className='redButton' onClick={() => navigate('/login')}>Log In</button>
              <button className='redButton' onClick={() => navigate('/signup')}>Sign Up</button>
            </React.Fragment>
        }
        <button className='redButton' onClick={() => navigate(`/comments/${episodeCode}`)}>Comments</button>
      </div>
    </div>
  )
}

export default NavBar;